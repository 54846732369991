import babushka1 from '../assets/photos/1.jpg'

function App() {
  return (
  <div className="container-fluid">
    <div className="row">
        <div className="col-12 col-lg-4 p-5 pb-0">

        <h1>Babushka Boy</h1>

        <div className="mt-5">

            <h3>ba·​bush·​ka</h3>
            <i>noun</i>

            <ol>
              <li className="py-2 pt-4">
              (in Poland and Russia) an old woman or grandmother.
              </li>
              <li style={{ color: "#FF0000" }}>
              wrapping blankets around my dog's head when he gets cold
              </li>
            </ol>

        </div>

      </div>
      <div className="col-12 col-lg-8 m-0 pt-3 pt-lg-5 text-center">

          <img src={babushka1} className="mw-100 mh-100" alt="Babushka dog"/>

      </div>
    </div>
  </div>
  );
}

export default App;
